import React from 'react';
import { useTranslation } from 'react-i18next';

import { getRandomIndex } from '@/utils/random';
import { riskPregnantText } from '@/utils/riskPregnant';
import Tag from '@/components/Tag';

interface ProductHeaderProps {
  product: {
    name: string;
    image: {
      url: string;
    };
    risk_pregnant: string;
    risk_info_text: string;
  };
  lang: string;
}

const ProductHeader: React.FC<ProductHeaderProps> = ({ product, lang }) => {
  const { t } = useTranslation();

  const riskPregnant = (riskType) => {
    const shortcutRisk = riskPregnantText[lang][riskType];

    return (
      shortcutRisk.first[getRandomIndex(shortcutRisk.first.length)] +
      shortcutRisk.second[getRandomIndex(shortcutRisk.second.length)] +
      shortcutRisk.third[getRandomIndex(shortcutRisk.third.length)]
    );
  };

  const riskPregnantSwitch = (risk) => {
    switch (risk) {
      case `none`:
        return riskPregnant(`textNone`);
      case `medium`:
        return riskPregnant(`textMedium`);
      default:
        return riskPregnant(`textHigh`);
    }
  };

  return (
    <div>
      <h1>{product.name}</h1>
      <div className="flex lg:flex-row flex-col gap-6">
        <img
          src={product.image.url.split(`?`)[0]}
          alt={product.name}
          className="rounded-3xl lg:w-1/2 w-full"
        />

        <div>
          <Tag risk={product.risk_pregnant} />

          <div className="mt-6">
            <h3>{t(`commentTitle`)}</h3>
            <p className="mb-6">{riskPregnantSwitch(product.risk_pregnant)}</p>

            <h3>{t(`descriptionTitle`)}</h3>
            <p>{product.risk_info_text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductHeader;
