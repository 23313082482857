const riskPregnantText = {
  fr: {
    textNone: {
      first: [
        `C’est tout bon ! `,
        `Vous pouvez foncer et en profiter ! `,
        `C’est ok ! `,
      ],
      second: [
        `Pas de contre indication à consommer ce produit. `,
        `Aucun souci avec ce produit lors d’une grossesse. `,
        `Vous pouvez consommer ce produit en toute confiance. `,
      ],
      third: [
        `Comme pour tout, restez modérés dans les quantités et pensez à ne pas manger ou boire trop vite.`,
        `Veillez simplement à vous modérer dans les quantités et bien vous hydrater.`,
        `Ne négligez pas une règle élémentaire, une juste quantité, ne pas manger trop vite et boire pendant le repas.`,
      ],
    },
    textMedium: {
      first: [
        `Vous pouvez vous lancer et consommer ce produit, mais `,
        `Vous pouvez y aller, mais `,
        `Cet aliment peut être consommé lors d’une grossesse, mais `,
      ],
      second: [
        `soyez vigilant sur les recommandations ou contre-indications afférentes à la consommation de ce produit durant la grossesse. `,
        `faites attention aux recommandations ou contre-indications à la consommation de ce produit pendant votre grossesse. `,
        `prêtez une attention particulière aux recommandations de consommation de cet aliment pendant la grossesse. `,
      ],
      third: [
        `N’oubliez pas qu’en cas de doute il vaut mieux s’abstenir.`,
        `En cas de doute, abstenez-vous le temps de prendre un conseil médical. `,
        ` Si vous avez le moindre doute, ne consommez pas cet aliment et prenez conseil auprès de votre médecin pour la prochaine fois.`,
      ],
    },
    textHigh: {
      first: [
        `Ah ! Impossible d’envisager que vous puissiez consommer cet aliment. `,
        `Malheureusement vous ne pouvez pas consommer cet aliment. `,
        `Cet aliment est déconseillé lors de la grossesse. `,
      ],
      second: [
        `Les risques sont trop élevés pour votre santé et celle de l’enfant que vous portez. `,
        ` Il y a trop de risques pour votre santé et celle de votre enfant. `,
        `La contre-indication provient du fait que celui-ci n’est pas adapté au régime alimentaire de la grossesse. `,
      ],
      third: [
        `Préférez une alternative qui saura vous satisfaire sans prendre de risques.`,
        `Utilisez plutôt un aliment qui convient à votre régime alimentaire.`,
        ` Remplacez cet aliment par un quelque chose qui ne présente pas de risques pour vous !`,
      ],
    },
  },
  en: {
    textNone: {
      first: [`You can go for it and enjoy it! `],
      second: [`No contraindication to consume this product! `],
      third: [
        `As with everything, keep the quantities moderate and remember not to eat or drink too quickly.`,
      ],
    },
    textMedium: {
      first: [`You can go ahead and consume this product. `],
      second: [
        `Pay attention to the recommendations or contraindications relating to the consumption of this product during pregnancy. `,
      ],
      third: [`Remember that if in doubt it is better to abstain.`],
    },
    textHigh: {
      first: [`Oh! Impossible to imagine that you can consume that. `],
      second: [
        `The risks are too high for your health and that of the child you are carrying. `,
      ],
      third: [
        `Prefer an alternative that will satisfy you without taking any risks.`,
      ],
    },
  },
};
export { riskPregnantText };
